<template>
  <div>

    <v-card >
      <v-card-title class="headline">Task progress</v-card-title>

      <v-alert v-if="task && task.completed" type="success" prominent tile>
        <h3>Ready!</h3>
        <p class="font-weight-bold mb-0">{{ task.msg }}</p>
        <p>Task completed {{task.updated | ago}}.</p>
      </v-alert>
      <v-card-text v-if="task && !task.completed">      
        <v-progress-linear v-if="task.progress" v-model="task.progress" class="mb-4" />
        <p class="font-weight-bold mb-0">{{ task.msg }}</p>
        <p v-if="task.progress">{{task.progress}}%</p>
        <p>Last updated: {{task.updated | ago}}</p>

        <p class="text-muted">Please keep this tab open, we'll automatically refresh the task progress.</p>
      </v-card-text>

    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import adminService from "@/services/adminService";
import { EventBus } from '@/plugins/eventbus.js';
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Task",
  components: {
  },
  props: {
    event: Object,
  },
  data() {
    return {
      task: null,
    };
  },
  async mounted() {
    await this.getData();
    
    EventBus.$on('login-state-change', async user => {
      await this.getData();
    });
  },
  methods: {
    async getTask(tenantId, taskId) {
      this.task = (await adminService.getTask(tenantId, taskId)).data;
      console.log('model', this.task);
      //this.task.completed = true;

      if (this.task && !(this.task.completed || this.task.has_error)) {
        window.setTimeout(this.getData, 5000);
      }
    },

    async getData() {
      if (this.user) {
        await this.getTask(tenant.id, this.$route.params.taskId);
      }
    },

  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

